<script>
	export let titleText;
	export let backText = '';
	export let backLink = '';
	export let nextText = '';
	export let nextLink = '';
	export let big = false;
</script>

<div class="wrapper" class:big>
	<div class="links">
		{#if backText && backLink}
			<a href={backLink}>
				<svg class="icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
					<path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7" />
				</svg>

				{backText}
			</a>
		{/if}

		{#if nextText && nextLink}
			<a href={nextLink}>
				{nextText}

				<svg class="icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M10.5858 6.34317L12 4.92896L19.0711 12L12 19.0711L10.5858 17.6569L16.2427 12L10.5858 6.34317Z"
						fill="currentColor"
					/>
				</svg>
			</a>
		{/if}
	</div>

	<h1 class="title-big">{titleText}</h1>
</div>

<style>
	.wrapper {
		display: grid;
		grid: auto 1fr / 1fr;
		height: auto;
	}

	.links {
		display: flex;
	}

	.links a {
		flex: 1;
	}

	.links a:nth-child(2) {
		display: flex;
		justify-content: flex-end;
	}

	.big {
		height: 276px;
	}

	.icon {
		height: 18px;
	}

	a {
		display: grid;
		grid: 1fr / auto auto;
		grid-gap: 4px;
		justify-content: flex-start;
		align-items: center;
		color: black;
		font-size: 14px;
		text-decoration: none;
		padding: 24px 0;
	}

	a:active {
		background-color: var(--gf-white-light);
	}

	a:hover {
		opacity: 0.6;
	}
</style>
